
*,
*:after,
*:before {
  box-sizing: inherit;
}

html,
body {
  height: 100%;
}

html {
  box-sizing: border-box;
}