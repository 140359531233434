
html, body {
  background: $color-background;
}

body {
  @include font-text;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size-adjust: auto;
  color: $color-text;
  line-height: $line-height;
}

h1, h2, h3, h4, h5, h6 {
  @include font-header;
}

h1 {
  margin: 0.5em 0;
}