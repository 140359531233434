
footer {
  background: $color-footer-background;

  font-size: 0.8rem;
  line-height: $line-height;
  color: $color-footer-text;
  text-align: center;
  @include content-wrapping-element('padding-top');
  @include content-wrapping-element('padding-bottom');

  a {
    color: $color-footer-text;
  }

  p {
    margin: 0 0 0.2em 0;
  }
}

