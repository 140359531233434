// New colors
$color-himalaya: #686C17;
$color-pampas: #F5F5F0;
$color-green-waterloo: #1D1F06;
$color-tana: #DADDB9;
$color-gray-nickel: #C6C6C1;
$color-soft-amber: #CCBAAD;

// Colors to use
$color-primary: $color-himalaya;
$color-primary-light: $color-tana;
$color-secondary: $color-soft-amber;
$color-white: $color-pampas;
$color-black: $color-green-waterloo;

$color-background: $color-white;
$color-background-secondary: $color-secondary;
$color-text: $color-black;
$color-link: $color-primary;

$color-header-background: $color-primary;
$color-header-text: $color-white;

$color-tabs-text: $color-black;
$color-tabs-active-background: $color-primary;
$color-tabs-active-text: $color-white;
$color-tabs-focus-background: $color-secondary;
$color-tabs-focus-text: $color-black;

$color-footer-background: $color-primary;
$color-footer-text: $color-gray-nickel;

$color-teaser-border: $color-primary;
$color-teaser-background: $color-primary-light;

$color-button-background: $color-primary;
$color-button-text: $color-white;

// Line heights
$line-height-factor: 1.5;
$line-height: $line-height-factor * 1em;

@function strip-unit( $value ) {
  @return $value / ( $value * 0 + 1 );
}

@function rem-to-px( $value ) {
  @return strip-unit( $value ) * 16;
}

@function px-to-rem( $value ) {
  @return strip-unit( $value ) / 16;
}

@mixin font-text {
  font-family: 'Open Sans', serif;
}

@mixin font-header {
  font-family: 'Merriweather', serif;
}

@mixin fluid-sizing($attr, $min-vw, $max-vw, $min-size, $max-size) {
  #{$attr}: $min-size;

  @media screen and ( min-width: $min-vw ) {
    #{$attr}: calc( #{ $min-size } + #{ rem-to-px( $max-size ) - rem-to-px( $min-size ) } * ( ( 100vw - #{ $min-vw } ) / #{ strip-unit( $max-vw - $min-vw ) } ) );
  }

  @media screen and ( min-width: $max-vw ) {
    #{$attr}: $max-size;
  }
}

@mixin content-wrapping-element($attr, $factor: 1) {
  @include fluid-sizing($attr, 320px, 960px, 0.5rem * $factor, 1.5rem * $factor);
}

@mixin content-wrapping {
  margin: 0 auto;
  width: 100%;
  max-width: 960px;

  @include content-wrapping-element('padding-left');
  @include content-wrapping-element('padding-right');
}

@mixin button {
  background: $color-button-background;
  color: $color-button-text;
  font-weight: bold;
  padding: 0.5rem;
  border: 0;
}