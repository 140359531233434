
section[role="main"] {
  @include content-wrapping-element('padding-top');
  @include content-wrapping-element('padding-bottom');

  a {
    color: $color-link;
    text-decoration: none;
    border-bottom: 1px solid $color-link;
  }
}

.head-back {
  a {
    color: $color-text;
  }
}

.errors {
  @include content-wrapping-element('padding');
  border: 1px solid #c33;
  color: #c33;

  @include content-wrapping-element('margin-bottom');
}

.order-total {
  font-weight: 700;
}

.issuers {
  img {
    width: 100px;
    display: block;
    margin: 0 auto;
  }
}

.concert, .info-form, .order-total, .issuers {
  @include content-wrapping-element('padding');

  border: 1px solid $color-teaser-border;
  background: $color-teaser-background;

  @include content-wrapping-element('margin-bottom');

  input {
    outline: none;
    border: 1px solid $color-primary;
    @include content-wrapping-element('padding', 0.5);
  }

  input[type="text"], input[type="email"] {
    width: 100%;
  }

  label, h3 {
    display: block;
    @include font-text;
    font-weight: 700;
    font-size: 1rem;
    line-height: $line-height;
    margin: 0;
  }

  .ticket-type, .field {
    @include content-wrapping-element('margin-bottom', 0.5);

    .description {
      font-style: italic;
    }
  }
}

.actions {
  text-align: center;
  a {
    color: $color-text;
  }

  input {
    @include button;
  }
}

h2 {
  margin: 0 0 0.5em;
}