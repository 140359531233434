
.container {
  @include content-wrapping;
}

.layout-container {
  @include fluid-sizing('padding-top', 320px, 960px, 2rem, 3.25rem);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}