
header {
  background: $color-header-background;
  position: fixed;
  overflow: hidden;

  left: 0;
  top: 0;
  right: 0;
  z-index: 100;

  color: $color-header-text;

  @include fluid-sizing('padding-top', 320px, 960px, 0.25rem, 0.5rem);
  @include fluid-sizing('padding-bottom', 320px, 960px, 0.25rem, 0.5rem);

  h1 {
    margin: 0;
    @include fluid-sizing('font-size', 320px, 960px, 1rem, 1.5rem);
    font-weight: 400;
    line-height: $line-height;
  }
}

